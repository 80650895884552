import { ComponentRef, PageRef } from '@wix/editor-platform-sdk-types';

import { ControllerType, EditorAppContext, WixCodeRole } from '../types';
import {
  findControllerByType,
  getControllerByType,
  getControllersByType,
} from '../editorSDKHelpers';

export async function connectControllerWithSearchBox(
  appContext: EditorAppContext,
  controllerRef: ComponentRef,
  searchBoxRef: ComponentRef,
): Promise<void> {
  const { appDefinitionId, editorSDK, reportError } = appContext;
  try {
    await editorSDK.controllers.connect(appDefinitionId, {
      controllerRef,
      connectToRef: searchBoxRef,
      role: WixCodeRole.SearchBox,
    });
  } catch (error) {
    reportError(error);
  }
}

function getSearchAppControllerClassic(
  appContext: EditorAppContext,
  pageRef: PageRef,
  componentRef: ComponentRef,
): Promise<ComponentRef | undefined> {
  return getControllerByType(appContext, ControllerType.SearchApp, pageRef);
}

async function getSearchAppControllerEditorX(
  appContext: EditorAppContext,
  pageRef: PageRef,
  componentRef: ComponentRef,
): Promise<ComponentRef | undefined> {
  const { editorSDK, appDefinitionId, reportError } = appContext;
  try {
    const connectableControllers =
      await editorSDK.document.controllers.listConnectableControllers(
        appDefinitionId,
        { componentRef },
      );
    return connectableControllers.length
      ? connectableControllers[0].controllerRef
      : undefined;
  } catch (error) {
    reportError(error);
  }
}

export async function getSearchAppControllers(appContext: EditorAppContext) {
  return getControllersByType(appContext, ControllerType.SearchApp);
}

export async function isComponentConnected(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
): Promise<boolean> {
  const { editorSDK, appDefinitionId } = appContext;
  const connections = await editorSDK.document.controllers.listConnections(
    appDefinitionId,
    { componentRef },
  );
  const controllers = connections.map((connection) => connection.controllerRef);

  const controllerRef = await findControllerByType(
    appContext,
    controllers,
    ControllerType.SearchApp,
  );
  return !!controllerRef;
}

export async function connectSearchBox(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
) {
  const { editorSDK, appDefinitionId, isEditorX } = appContext;
  const pageRef = await editorSDK.components.getPage(appDefinitionId, {
    componentRef,
  });

  const getSearchAppController = isEditorX
    ? getSearchAppControllerEditorX
    : getSearchAppControllerClassic;

  const controllerRef = await getSearchAppController(
    appContext,
    pageRef,
    componentRef,
  );

  if (!controllerRef) {
    return;
  }

  return connectControllerWithSearchBox(
    appContext,
    controllerRef,
    componentRef,
  );
}
